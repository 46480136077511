<template>
  <div class="bodys">
    <div class="cont">
      <div class="cont-right">
        <div class="cont-center">
          <div class="cont_title">我要充值</div>
          <div class="cont_zi">
            <p>使用银行APP或网银</p>
            <p>转入资金到你的汇成国际账户</p>
          </div>
          <div class="kuang">
            <div class="kuang_nei">
              <div class="kuang_1">收款账户</div>
              <div class="kaung_inp">
                <div class="inp_name">收款人户名:</div>
                <div class="inp_right">
                  <div class="inp_sel">{{ payInfo.bank_name }}</div>
                  <div
                    class="inp_copy"
                    v-clipboard:copy="payInfo.bank_name"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyFail"
                  >
                    复制
                  </div>
                </div>
              </div>
              <div class="kaung_inp">
                <div class="inp_name">收款人账号:</div>
                <div class="inp_right">
                  <div class="inp_sel">{{ payInfo.bank_account }}</div>
                  <div
                    class="inp_copy"
                    v-clipboard:copy="payInfo.bank_account"
                    v-clipboard:success="copySuccess"
                    v-clipboard:error="copyFail"
                  >
                    复制
                  </div>
                </div>
              </div>
              <div class="kaung_inp">
                <div class="inp_name">开户行:</div>
                <div class="inp_right">
                  <div class="inp_sel">{{ payInfo.bank }}</div>
                  <div class="inp_copy">
                    <img
                      src="../../../../assets/img/wenhao.png"
                      class="wenhao"
                      alt=""
                      @click="kaihu"
                    />
                  </div>
                </div>
              </div>
              <div class="rech_flex">
                <div class="rech">充值金额:</div>
                <input
                  type="text"
                  placeholder="请输入充值金额"
                  v-model="moneyValue"
                  class="money"
                  autocomplete="off"
                  @input="formatValue(moneyValue)"
                />
              </div>
              <div class="rech_flex">
                <div class="rech">转账姓名:</div>
                <input
                  type="text"
                  placeholder="请输入转账姓名"
                  v-model="nameValue"
                  class="money"
                />
              </div>
              <button class="btns" @click="btns">提交申请</button>
              <div class="guize">
                <div style="font-weight: bold">充值规则:</div>
                <div>
                  <p>
                    1.转账充值请在工作日转账，非工作日转账需要等下一个工作日到账:
                  </p>
                  <p>
                    2.转账充值前请核对好收款银行信息，转账备注汇成国际注册手机号，方便财务对账;
                  </p>
                  <p>
                    3.转账充值提交申请后，请用手机银行或网银向收款银行账号转账，一般需要2个小时左右到账，延迟到账请联系客服加急处理。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 遮罩层 -->
    <div class="selected_zhe" v-show="selected_zhe"></div>
    <!-- 成功 弹窗 -->
    <div class="accept" v-show="accept_tan_suc">
      <div class="tishi">
        <p>转账申请已提交，请打开手机银行</p>
        <p>APP尽快把充值金额转出到如下收款</p>
        <p>账户，完成转账充值流程。</p>
      </div>
      <button class="zhidao" @click="zhidao">我知道了</button>
    </div>
    <!-- 开户行 -->
    <div class="kaihu" v-show="kaihu_tan">
      <div class="kai_title">如何选择开户行</div>
      <div class="kai_2">
        <div class="llan">方式一</div>
        <div class="heis">
          按关键词【网商】搜索并选择【浙江网商银行股份有限公司】
        </div>
      </div>
      <div class="kai_2">
        <div class="llan">方式二</div>
        <div class="heis">
          选择【民营银行】，地区选择【浙江杭州】后，再选择【浙江网商银行股份有限公司】
        </div>
      </div>
      <div class="kai_2">
        <div class="llan">方式三</div>
        <div class="heis">
          收款银行标签页中，按银行首字母【W】查找【网商银行】或【Z】查找【浙江网商银行】
        </div>
      </div>
      <button class="zhidaos" @click="kaihu_zhidao">我知道了</button>
    </div>
  </div>
</template>

<script>
import { offlinePayInfo, offlinePay } from "@network/recharge";
export default {
  name: "Recharge2",
  data() {
    return {
      moneyValue: "",
      nameValue: "",
      value: "",
      selected_zhe: false, //遮罩层
      accept_tan_suc: false, //成功
      kaihu_tan: false, //开户 弹窗
      payInfo: {},
    };
  },
  created() {
    this.offlinePayInfo();
  },
  components: {},
  methods: {
    //支付信息
    offlinePayInfo() {
      offlinePayInfo().then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({message:"请重新登录",offset: 300});
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取支付信息失败",
            offset: 300
          });
          return false;
        } else {
          this.payInfo = res.data;
        }
      });
    },
    // 只允许输入金额类型，最大两位小数（如：3.88）
    formatValue(val) {
      val = val.replace(/(^\s*)|(\s*$)/g, "");
      if (!val) return (this.moneyValue = "");
      val = val.replace(/[^\d.]/g, "");
      val = val.replace(/^\./g, "");
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3");
      this.moneyValue = val;
    },
    // 开户行  弹窗 知道
    kaihu_zhidao() {
      this.selected_zhe = false;
      this.kaihu_tan = false;
    },
    // 成功
    btns() {
      if (this.moneyValue == "") {
        this.$message.error({
          message: "充值金额不能为空",
          offset: 300
        });
        return false;
      } else if (parseFloat(this.moneyValue) <= 0) {
        this.$message.error({
          message: "充值金额不能小于0",
          offset: 300
        });
        return false;
      } else if (this.nameValue == "") {
        this.$message.error({
          message: "转账姓名不能为空",
          offset: 300
        });
        return false;
      }
      offlinePay(this.moneyValue * 100, this.nameValue).then((res) => {
        console.log(res);
        if (res.code != 0) {
          this.$message.error({
            message: res.msg,
            offset: 300
          });
          return false;
        } else {
          this.selected_zhe = true;
          this.accept_tan_suc = true;
        }
      });
    },
    // 成功 知道
    zhidao() {
      this.selected_zhe = false;
      this.accept_tan_suc = false;
      window.location.reload();
    },
    copySuccess() {
      this.$message.success({message:"复制成功",offset: 300});
    },
    copyFail() {
      this.$message.error({message:"复制失败",offset: 300});
    },
    kaihu() {
      this.selected_zhe = true;
      this.kaihu_tan = true;
    },
  },
};
</script>
<style lang='less' scoped>
@import "../../../../assets/css/vipCenter/ChildComps/recharge/personal2.less";
</style>
